.body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background: #f0f2f5;
  color: #333;
}

.navbar {
  background-color: #2c3e50;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 15vw;
  width: 85vw;
  height: 1vh;
}
/* .navbar h1 {
  margin: 0;
  font-size: 24px;
} */
.navbar button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
}
.navbar button:hover {
  color: #1abc9c;
}
.upload-container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  margin: auto;
  max-width: 60vw;
  padding: 20px;
}
.file-upload {
  background-color: white;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.file-upload label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
.file-upload input[type="file"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.submit-button {
  width: 100%;
  padding: 12px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}
.submit-button:hover {
  background-color: #45a049;
}
.file-upload-class {
  padding: 10px;
  margin-top: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 2px dashed #5f5b5b;
}
.floor-selection-container,
.ordered-floors-container {
  display: inline-block;
  vertical-align: top;
  margin: 10px;
}

.floor-selection-container select,
.ordered-floors-container select {
  width: 200px;
  height: 100px;
}

.floor-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.floor-buttons button {
  margin: 5px;
  padding: 5px 10px;
}
.floor-order-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.floor-order-buttons button {
  margin: 2px;
  padding: 5px 10px;
}

.floors-list select {
  width: 100%;
  height: 100%;
  border: none;
}

.floors-management-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.floors-list,
.ordered-floors-list {
  flex: 1;
  margin: 0 10px;
}

.floor-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.floors-buttons button {
  margin: 5px 0;
  padding: 10px;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
}

.floors-buttons button:hover {
  background-color: #45a049;
}

.textarea-class {
  padding: 10px;
  width: 100%;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  line-height: 2;
  margin-bottom: 10px;
  resize: vertical;
}

.sidebar {
  width: 200px;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  background-color: #2c3e50;
  color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
}

/* .sidebar h1 {
  margin-bottom: 20px;
} */

.sidebar button,
.sidebar a {
  background-color: #075f6c;
  color: white;

  padding: 10px 20px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  width: 80%;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-size: 1em;
  transition: background-color 0.3s;
}
.sidebar button:hover,
.sidebar a:hover {
  background-color: #45a049;
  color: white;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.file-upload-class {
  padding: 10px;
  margin-top: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 2px dashed #5f5b5b;
}

.mapping-container {
  max-width: 60vw;
  /* margin: 20px; */
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.mapping-item {
  margin-bottom: 15px;
}

.mapping-label {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  color: #333;
  font-weight: 500;
}

.mapping-select {
  width: 100%;
  padding: 8px 10px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.mapping-select option {
  padding: 8px;
}

.windows-table-container {
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.windows-table {
  width: 100%;
  border-collapse: collapse;
}

.windows-table th,
.windows-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.windows-table th {
  background-color: #f4f4f4;
}

.action-buttons {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.action-buttons button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-buttons button:hover {
  background-color: #218838;
}

.copy-windows-section {
  margin-top: 20px;
}

.copy-windows-section select {
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.copy-windows-section button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.copy-windows-section button:hover {
  background-color: #218838;
}
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-info {
  margin: 0 10px;
  font-size: 16px;
  color: #333;
}

.pagination-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px;
  border: none;
  background: lightgray;
  cursor: pointer;
}

.tabs button.active {
  background: #007bff;
  color: white;
}

.image-preview img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

.form-section {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
}
.progress-indicator {
  display: flex;
  gap: 10px;
  margin-bottom: 2rem;
  padding: 1rem 0;
  overflow-x: auto;
  margin-left: 10vw;
}

.progress-step {
  padding: 0.5rem 1rem;
  border: 2px solid #007bff;
  border-radius: 20px;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.3s ease;
}

.progress-step.active {
  background-color: #007bff;
  color: white;
}
@media (max-width: 768px) {
  .upload-container {
    margin: 8vh 1rem 2rem 1rem;
    width: auto;
    padding: 1rem;
  }
}
/* .upload-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
} */

.form-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.submit-button {
  background-color: #007bff;
  color: white;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.image-preview img {
  max-width: 300px;
  max-height: 200px;
  border-radius: 8px;
  margin-top: 1rem;
}
/* Add these new styles to your existing FileUpload.css */

.scene-setup-content {
  padding: 1rem;
}

.section-title {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.section-description {
  color: #666;
  margin-bottom: 2rem;
}

.data-lists-section {
  margin-bottom: 2rem;
  background: #f8fafc;
  border-radius: 8px;
  padding: 1.5rem;
}

.list-display-section {
  margin-bottom: 1.5rem;
}

.list-title {
  font-size: 1.1rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e2e8f0;
}

.list-items {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.list-item {
  background: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #2c3e50;
  border: 1px solid #e2e8f0;
  display: inline-block;
}

.floor-management-section {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  margin: 2rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.subsection-title {
  font-size: 1.25rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
}

.floors-management-container {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 1.5rem;
  align-items: start;
}

.floors-list {
  flex: 1;
}

.floors-list h4 {
  color: #2c3e50;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.floor-select {
  width: 100%;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 0.5rem;
  background: white;
  font-size: 0.9rem;
}

.floor-select option {
  padding: 0.5rem;
}

.floor-actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}

.action-button {
  background: #3498db;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.action-button:hover {
  background: #2980b9;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
}

.checkbox-wrapper input[type="checkbox"] {
  width: 1.2rem;
  height: 1.2rem;
}

.checkbox-wrapper label {
  color: #2c3e50;
  font-weight: 500;
}

.checkbox-hint {
  color: #666;
  font-size: 0.9rem;
  margin-left: 0.5rem;
}

/* Modify some existing styles to match PV Panel setup */

.pv-panel-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
  margin-left: 250px;
  height: auto;
  min-height: calc(100vh - 60px);
  overflow-y: auto;
}

.setup-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  height: auto;
  margin-bottom: 2rem;
}

.card-header {
  text-align: center;
  margin-bottom: 2rem;
}

.card-header h1 {
  color: #2c3e50;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.card-header p {
  color: #666;
  font-size: 1rem;
  margin: 0;
  margin-bottom: 2rem;
}

.submit-button {
  width: 100%;
  padding: 1.2rem;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 2rem;
}

.submit-button:hover {
  background: #2980b9;
  transform: translateY(-1px);
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .pv-panel-container {
    margin-left: 0;
    padding: 1rem;
  }

  .floors-management-container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .floor-actions {
    flex-direction: row;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .list-items {
    flex-direction: column;
  }

  .list-item {
    width: 100%;
  }

  .setup-card {
    padding: 1rem;
  }

  .card-header h1 {
    font-size: 1.5rem;
  }
}

/* Add these styles to your FileUpload.css */

.window-management-content {
  padding: 1rem;
}

.search-section {
  background: #f8fafc;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.search-controls {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.search-input-wrapper {
  flex: 1;
  display: flex;
  gap: 1rem;
}

.search-input {
  flex: 2;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.95rem;
  transition: border-color 0.2s ease;
}

.search-input:focus {
  border-color: #3498db;
  outline: none;
}

.window-select {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.95rem;
  background: white;
}

.delete-button {
  padding: 0.75rem 1.5rem;
  background: #ef4444;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.delete-button:hover {
  background: #dc2626;
}

.windows-data-section {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.section-title {
  padding: 1.5rem;
  margin: 0;
  border-bottom: 1px solid #e2e8f0;
  font-size: 1.25rem;
  color: #2c3e50;
}

.windows-table-wrapper {
  overflow-x: auto;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 1rem 1.5rem;
  text-align: left;
  border-bottom: 1px solid #e2e8f0;
}

.data-table th {
  background: #f8fafc;
  font-weight: 600;
  color: #2c3e50;
}

.data-table tr:hover {
  background: #f8fafc;
}

.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  gap: 1rem;
}

.pagination-button {
  padding: 0.75rem 1.5rem;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.pagination-button:hover:not(:disabled) {
  background: #2980b9;
}

.pagination-button:disabled {
  background: #cbd5e1;
  cursor: not-allowed;
}

.page-info {
  color: #64748b;
  font-size: 0.95rem;
}

.actions-section {
  margin-bottom: 2rem;
}

.action-button {
  width: 100%;
  padding: 1rem;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.action-button:hover {
  background: #2980b9;
  transform: translateY(-1px);
}

.copy-section {
  background: #f8fafc;
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 1.5rem;
}

.copy-section h3 {
  margin: 0 0 1rem 0;
  color: #2c3e50;
}

.copy-controls {
  display: flex;
  gap: 1rem;
}

.project-select {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.95rem;
  background: white;
}

.copy-button {
  padding: 0.75rem 1.5rem;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.copy-button:hover:not(:disabled) {
  background: #2980b9;
}

.copy-button:disabled {
  background: #cbd5e1;
  cursor: not-allowed;
}

.loading-state {
  text-align: center;
  padding: 3rem;
  color: #64748b;
}

.loading-spinner {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .search-controls {
    flex-direction: column;
  }

  .search-input-wrapper {
    flex-direction: column;
  }

  .copy-controls {
    flex-direction: column;
  }

  .data-table {
    font-size: 0.9rem;
  }

  .data-table th,
  .data-table td {
    padding: 0.75rem;
  }
}
/* Add these styles to your FileUpload.css */

.info-section {
  margin-top: 2rem;
  padding: 1.5rem;
  background: #f8fafc;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.info-title {
  color: #2c3e50;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.info-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.info-list li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.75rem;
  color: #64748b;
  font-size: 0.95rem;
  line-height: 1.5;
}

.info-list li:before {
  content: "•";
  position: absolute;
  left: 0.5rem;
  color: #3498db;
}

.info-list li:last-child {
  margin-bottom: 0;
}

.file-field {
  margin-bottom: 2rem;
}

.file-field-label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: #2c3e50;
}

.file-upload-area {
  border: 2px dashed #e2e8f0;
  border-radius: 8px;
  padding: 2.5rem 1.5rem;
  text-align: center;
  background: #f8fafc;
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;
}

.file-upload-area.dragging {
  border-color: #3498db;
  background: #ebf8ff;
}

.file-upload-area:hover {
  border-color: #3498db;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-upload-content {
  pointer-events: none;
}

.upload-icon {
  font-size: 2rem;
  color: #3498db;
  margin-bottom: 1rem;
}

.file-name {
  display: block;
  margin-top: 0.75rem;
  color: #2c3e50;
  font-size: 0.9rem;
  font-weight: 500;
}

.upload-form {
  margin-bottom: 2rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .info-section {
    padding: 1rem;
  }

  .file-upload-area {
    padding: 1.5rem 1rem;
  }

  .upload-icon {
    font-size: 1.5rem;
  }
}
/* Add these styles to your FileUpload.css */

.stats-section {
  margin-bottom: 2rem;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 8px;
}

.stat-card {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.stat-label {
  color: #64748b;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.stat-value {
  color: #2c3e50;
  font-size: 2.5rem;
  font-weight: 600;
}

.stat-description {
  color: #94a3b8;
  font-size: 0.9rem;
}

.file-field {
  margin: 2rem 0;
}

.file-field-label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: #2c3e50;
}

.file-upload-area {
  border: 2px dashed #e2e8f0;
  border-radius: 8px;
  padding: 2.5rem 1.5rem;
  text-align: center;
  background: #f8fafc;
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;
}

.file-upload-area.dragging {
  border-color: #3498db;
  background: #ebf8ff;
}

.file-upload-area:hover {
  border-color: #3498db;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-upload-content {
  pointer-events: none;
}

.upload-icon {
  font-size: 2rem;
  color: #3498db;
  margin-bottom: 1rem;
}

.file-name {
  display: block;
  margin-top: 0.75rem;
  color: #2c3e50;
  font-size: 0.9rem;
  font-weight: 500;
}

.status-message {
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  text-align: center;
  font-weight: 500;
}

.status-message.success {
  background: #dcfce7;
  color: #166534;
  border: 1px solid #bbf7d0;
}

.status-message.error {
  background: #fee2e2;
  color: #991b1b;
  border: 1px solid #fecaca;
}

.info-section {
  margin-top: 2rem;
  padding: 1.5rem;
  background: #f8fafc;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.info-title {
  color: #2c3e50;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.info-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.info-list li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.75rem;
  color: #64748b;
  font-size: 0.95rem;
  line-height: 1.5;
}

.info-list li:before {
  content: "•";
  position: absolute;
  left: 0.5rem;
  color: #3498db;
}

.info-list li:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .stat-value {
    font-size: 2rem;
  }

  .file-upload-area {
    padding: 1.5rem 1rem;
  }

  .info-section {
    padding: 1rem;
  }
}
/* Add these styles to your FileUpload.css */

.edit-mode-section {
  margin: 1.5rem 0;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.checkbox-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.checkbox-input {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #e2e8f0;
  border-radius: 4px;
  cursor: pointer;
}

.checkbox-label {
  font-weight: 500;
  color: #2c3e50;
  cursor: pointer;
}

.checkbox-tooltip {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #64748b;
  line-height: 1.4;
}

.file-upload-area {
  border: 2px dashed #e2e8f0;
  border-radius: 8px;
  padding: 2.5rem 1.5rem;
  text-align: center;
  background: #f8fafc;
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;
}

.file-upload-area.dragging {
  border-color: #3498db;
  background: #ebf8ff;
}

.file-upload-area:hover {
  border-color: #3498db;
}

.upload-icon {
  font-size: 2rem;
  color: #3498db;
  margin-bottom: 1rem;
}

.status-message {
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  text-align: center;
  font-weight: 500;
}

.status-message.success {
  background: #dcfce7;
  color: #166534;
  border: 1px solid #bbf7d0;
}

.status-message.error {
  background: #fee2e2;
  color: #991b1b;
  border: 1px solid #fecaca;
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1.5rem;
}

.submit-button:hover:not(:disabled) {
  background: #2980b9;
  transform: translateY(-1px);
}

.submit-button:disabled {
  background: #cbd5e1;
  cursor: not-allowed;
}

.submit-button.submitting {
  background: #cbd5e1;
  cursor: wait;
}

@media (max-width: 768px) {
  .checkbox-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .checkbox-tooltip {
    margin-top: 0.25rem;
  }

  .file-upload-area {
    padding: 1.5rem 1rem;
  }

  .upload-icon {
    font-size: 1.5rem;
  }
}
/* Update these styles in your FileUpload.css */

.pv-panel-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 60px; /* Reduced from previous value */
  margin-left: 250px;
  min-height: calc(100vh - 60px);
  overflow-y: auto;
}

.setup-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-top: 1rem; /* Added to give some space from top */
  height: auto;
}

.card-header {
  text-align: center;
  margin-bottom: 2rem;
  padding-top: 0; /* Remove top padding */
}

.card-header h1 {
  color: #2c3e50;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  margin-top: 0; /* Remove top margin */
}

.card-header p {
  color: #666;
  font-size: 1rem;
  margin: 0;
}

.file-field {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* Adjust top position for navbar */
.top-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px; /* Fixed height for navbar */
  z-index: 1000;
}

/* Adjust left navbar position */
.left-navbar {
  position: fixed;
  top: 60px; /* Start below top navbar */
  left: 0;
  width: 250px;
  height: calc(100vh - 60px);
  z-index: 999;
}
