.top-navbar {
  background-color: #2c3e50;
  padding: 1rem 2rem;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.top-navbar h1 {
  color: white;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
}

.nav-links {
  display: flex;
  gap: 2rem;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem;
  transition: color 0.2s ease;
}

.nav-link:hover {
  color: #3498db;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar-content {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
  }

  .nav-links {
    gap: 1rem;
  }
}
