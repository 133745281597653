/* Improved search container positioning */
.search-container {
  position: relative; /* Changed from sticky to relative */
  margin: 24px 24px 24px auto; /* Auto margin-left to push to right */
  width: calc(100% - 274px); /* Account for left navbar */
  display: flex;
  justify-content: flex-end; /* Align content to right */
  padding: 0;
  background: none;
  border: none;
}

.search-container input {
  width: 300px; /* Fixed width for search input */
  padding: 12px 16px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: white;
  transition: all 0.2s ease;
}

.search-container input:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
}

/* Grid container adjustments */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  padding: 0 24px 24px 24px;
  margin-left: 250px;
  width: calc(100% - 280px);
}

/* Rest of the styles remain the same */
.project {
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #e0e0e0;
  height: 100%;
}

.project:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.project img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin: 0;
}

.project h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #2c3e50;
}

.project p {
  margin: 0;
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}

.edit-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  margin-top: auto;
}

.edit-button:hover {
  background-color: #2980b9;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .search-container {
    width: calc(100% - 224px);
  }
}

@media (max-width: 768px) {
  .grid-container {
    margin-left: 200px;
    width: calc(100% - 230px);
  }

  .search-container {
    width: calc(100% - 200px);
  }

  .search-container input {
    width: 250px;
  }
}
