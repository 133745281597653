.layout-container {
  display: flex;
  height: 10vh;
}

.left-navbar {
  width: 250px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #2c3e50;
  color: white;
  z-index: 1000;
}

.nav-content {
  padding-top: 80px; /* Space for top navbar */
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-item {
  width: 100%;
  transition: all 0.3s ease;
}

.nav-item a {
  display: block;
  padding: 15px 25px;
  color: #b8c7ce;
  text-decoration: none;
  font-size: 15px;
  transition: all 0.3s ease;
}

.nav-item:hover a {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-item.active {
  background-color: #34495e;
}

.nav-item.active a {
  color: white;
  font-weight: 500;
}

.nav-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-content {
  flex: 1;
  margin-left: 250px;
  padding: 80px 30px 30px;
  background-color: #f4f6f8;
  height: 10vh;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .left-navbar {
    width: 200px;
  }

  .main-content {
    margin-left: 200px;
  }
}

@media (max-width: 576px) {
  .left-navbar {
    width: 0;
    transform: translateX(-100%);
  }

  .left-navbar.open {
    width: 200px;
    transform: translateX(0);
  }

  .main-content {
    margin-left: 0;
  }
}
