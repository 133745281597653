/* KMLSetup.css */

.kml-setup {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.kml-setup h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}

.kml-setup p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.5;
}

.kml-setup ol {
  padding-left: 20px;
  margin-bottom: 20px;
}

.kml-setup ol li {
  margin-bottom: 20px;
  font-size: 1rem;
  color: #444;
  line-height: 1.6;
}

.kml-setup iframe {
  display: block;
  margin: 10px auto;
  max-width: 100%;
  border: none;
  border-radius: 5px;
}

.kml-setup a {
  color: #007bff;
  text-decoration: none;
}

.kml-setup a:hover {
  text-decoration: underline;
}

.kml-setup form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.kml-setup input[type="file"] {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #fff;
}

.kml-setup button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.kml-setup button:hover {
  background-color: #0056b3;
}
